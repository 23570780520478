import React, { Fragment } from "react";
import { Script } from "gatsby";
import { useSiteMetadata } from "../hooks/defaults/Use-SiteMetadata";

const RootElement = ({ children }) => {
	const { gTag } = useSiteMetadata();

	return (
		<Fragment>
			<Script
				src={`https://www.googletagmanager.com/gtag/js?id=${gTag}G-D4VEG8ZWES`}
				strategy="off-main-thread"
				forward={[`gtag`]}
			/>
			<Script
				id="gtag-config"
				strategy="off-main-thread"
				dangerouslySetInnerHTML={{
					__html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', '${gTag}', { send_page_view: false })`,
				}}
			/>
			<div>{children}</div>
		</Fragment>
	);
};

export default RootElement;
