import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
	const { site } = useStaticQuery(
		graphql`
			query SiteMetaData {
				site {
					siteMetadata {
						title
						tagLine
						description
						siteUrl
						phoneNum
						mainArea
						mainService
						googleMap
						address {
							streetAddress
							addressLocality
							addressRegion
							postalCode
							addressCountry
							longitude
							latitude
						}
						googleData {
							id
							type
							openingHours
							mainEntityOfPage
							additionalType
							hasMap
							sameAs
							description
							logo
						}
						gTag
						jotForm
						tradingName
					}
				}
			}
		`
	);
	return site?.siteMetadata;
};
